import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-dashboard-input-field',
    templateUrl: './dashboard-input-field.component.html',
    styleUrls: ['./dashboard-input-field.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgClass, MatIconModule]
})
export class DashboardInputFieldComponent implements OnInit {
    @Input() icon: string;
    @Input() value: string;
    @Input() closeIcon: boolean;
    @Input() placeholder: string;
    @Input() label: string;
    @Input() name: string = 'field';
    @Input() disabled: boolean;
    @Input() cta: string;
    @Input() type: string;
    @Input() validators: ValidatorFn[] = [];

    @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Output() onReset: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCTAClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onValidate: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('field', { static: true }) field: ElementRef;

    public form: UntypedFormGroup;

    constructor() {}

    public reset() {
        this.form.reset();
        this.onReset.emit();
    }

    public focus() {
        setTimeout(() => {
            this.field.nativeElement.focus();
        }, 0);
    }

    public blur() {
        setTimeout(() => {
            this.field.nativeElement.blur();
        }, 0);
    }

    public submit(value: string) {
        console.log('submit called', value);
        this.onSubmit.emit(value);
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (!this.form) {
            this.form = new UntypedFormGroup({
                [this.name]: new UntypedFormControl(
                    { value: '', disabled: this.disabled },
                    this.validators
                )
            });
        }

        if (changes.value?.currentValue) {
            this.form.controls[this.name].setValue(changes.value.currentValue);
        }

        if (changes.disabled?.currentValue != null) {
            if (changes.disabled?.currentValue === true) {
                this.form.controls[this.name].disable();
            } else {
                this.form.controls[this.name].enable();
            }
        }
    }
}
