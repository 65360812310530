<form
    [formGroup]="form"
    (submit)="submit(field.value); $event.preventDefault(); $event.stopPropagation()"
>
    <div class="input-container">
        <label *ngIf="label"
            ><span>{{ label }}</span
            ><a [attr.aria-label]="cta" class="cta" *ngIf="cta" (click)="onCTAClick.emit()">{{
                cta
            }}</a></label
        >
        <input
            #field
            [formControlName]="name"
            class="field"
            [ngClass]="{ icon: icon != null, disabled: disabled }"
            [value]="value"
            (keyup)="onKeyup.emit(field.value); onValidate.emit(form.controls[name].valid)"
            (focus)="onFocus.emit()"
            (blur)="onBlur.emit(); onValidate.emit(form.controls[name].valid)"
            (keydown.enter)="
                submit(field.value); $event.preventDefault(); $event.stopPropagation()
            "
            [attr.type]="type"
            [attr.placeholder]="placeholder"
        />
        <mat-icon
            svgIcon="circle-xmark"
            class="close-button"
            *ngIf="closeIcon && (form?.value)[name]?.length > 0"
            (click)="reset(); field.focus()"
        ></mat-icon>
        <button
            aria-label="Submit"
            *ngIf="icon"
            type="submit"
            class="button"
            [disabled]="!(form?.value)[name]"
        >
            <mat-icon [svgIcon]="icon" [ngClass]="{ disabled: !(form?.value)[name] }"></mat-icon>
        </button>
    </div>
</form>

